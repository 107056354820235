module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CDF Equipamentos","lang":"pt-BR","description":"Projetamos e produzimos equipamentos para transporte hospitalar, resgate e ressonância magnética. Tendo como prioridade: Segurança, conforto, qualidade e praticidade!","short_name":"cdf","start_url":"/","background_color":"#FFF","theme_color":"#52668C","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"33cdbdd4b9063c66d976353d7f3604d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-51GZ3D1PKD","cookieName":"CookieConsent","anonymize":true,"allowAdFeatures":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
