// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-produtos-jsx": () => import("./../../../src/pages/produtos.jsx" /* webpackChunkName: "component---src-pages-produtos-jsx" */),
  "component---src-pages-sobre-nos-jsx": () => import("./../../../src/pages/sobre-nos.jsx" /* webpackChunkName: "component---src-pages-sobre-nos-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/product.template.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}

