/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.onInitialClientRender = () => {
  // initialize tawk.to script
  var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
  (function() {
    var s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/6127a972649e0a0a5cd31927/1fe1erqbm';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
};
